<template>
 <Layout style="background-color:white;">
  <PageHeader :title="title" :items="items" />
  <div class="container-fluid">

   <div class="row">
    <div class="col-md-12">

     <VueFileAgent
             ref="vueFileAgent"
             :theme="'default'"
             :multiple="true"
             :deletable="true"
             :meta="true"
             :maxSize="'999MB'"
             :maxFiles="1"
             :helpText="'Zip dosyası yükleyebilirsiniz - Sürükle Bırak yöntemi aktiftir'"
             :errorText="{
                 type: 'İzin verilmeyen dosya türüdür.Yüklemesi yapılmayacaktır ',
                 size: 'Size sunulan yükleme aşımını geçtiniz',
             }"
             @select="filesSelected($event)"
             @beforedelete="onBeforeDelete($event)"
             @delete="fileDeleted($event)"
             v-model="fileRecords"
     ></VueFileAgent>
     <b-button variant="outline-primary" @click="uploadFiles()" :disabled="!fileRecordsForUpload.length" class="mt-3">Upload</b-button>

    </div>
   </div>
  </div>
 </Layout>
</template>
<script>
 import Layout from "../../layouts/main";
 import PageHeader from "@/components/page-header";
 import {axiosInstance} from '../../../helpers/axios';
 export default {
  components: {
   Layout,
   PageHeader
  },
  data() {
   return {
    title: "Güncelleme Yükleme paneli",
    items: [{
     text: "Anasayfa"
    },
     {
      text: "Güncelleme Yükleme paneli",
      active: true
     }
    ],
    fileRecords: [],
    fileRecordsForUpload: [],
   }
  },
  methods:{
   uploadFiles: function () {
      let vm = this;
      try {
       let formData = new FormData();
       vm.fileRecordsForUpload.map((value)=>{
        formData.append('file', value.file);
       });


       console.log(formData);
       axiosInstance.post('update/upload',formData,{
        headers: {
         'Content-Type': 'multipart/form-data',
        }
       }).then((response)=>{
        if(response.data.error==true)
        {

         vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz ", {
          title: `Uyari !`,
          variant: "warning",
          solid: true
         });

        }else{

         vm.$bvToast.toast("Güncelleme Sisteme Yüklendi", {
          title: `Bildirim !`,
          variant: "info",
          solid: true
         });

        }
        vm.fileRecordsForUpload=[];
       }).catch((error)=> {
        vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz "+error, {
         title: `Uyari !`,
         variant: "warning",
         solid: true
        });
       });



      }catch (e) {
       vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz "+e, {
        title: `Uyari !`,
        variant: "warning",
        solid: true
       });
      }
     },
     deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
     },
     filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
     },
     onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
       // queued file, not yet uploaded. Just remove from the arrays
       this.fileRecordsForUpload.splice(i, 1);
       var k = this.fileRecords.indexOf(fileRecord);
       if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
       this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
      }
     },
     fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
       this.fileRecordsForUpload.splice(i, 1);
      } else {
       this.deleteUploadedFile(fileRecord);
      }
     },
  }
 }
</script>